//import { alertConstants } from '../constants';

export function modal(state = {}, action) {
  switch ( action.type ) {
    case "OPEN_CONFIRM_MODAL":
      return { visible: true, ...action.options };
    case "CLOSE_CONFIRM_MODAL":
      return { visible: false };
    default:
      return state
  }
}