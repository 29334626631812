import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class DebriefMenu extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            links: [
                // { name: 'Players', url: '/players' },
                // { name: 'Debrief', url: '/debrief/overview' },
                { name: 'Pareto', url: '/debrief/pareto' },
                { name: 'Issue Types', url: '/debrief/types' },
                { name: 'Offers', url: '/debrief/offers' },
                { name: 'Leaderboard', url: '/debrief/leaderboard' }
            ]
        }

        // Bindings

    }

    // componentWillReceiveProps(newProps){
    //     this.setState({ game_id: newProps.game_id })
    // }

    render(){

        const { game } = this.props;
        const links = this.state.links.map( (l, li) => {
            const active = this.props.location.pathname.includes(l.url) ? 'btn-primary' : 'btn-link';
            return <Link key={'dm-' + li} to={`/games/${game.data._id}${l.url}`} 
                className={`btn ${active} btn-sm ml-3 ${active}`}>{l.name}</Link>
        })

        return <div>
                <div className="d-inline-block">{ links }</div>
                <button className="btn btn-sm btn-outline-primary ml-3" onClick={ e => window.location.reload() }>
                    <FontAwesomeIcon icon="redo-alt" />
                </button>
            </div>
    }

}

function mapStateToProps(state) {
    const { game } = state;
    return {
        game
    };
}

const connectedPage = connect(mapStateToProps)(DebriefMenu);
export { connectedPage as DebriefMenu }; 
// export default DebriefMenu