import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Constants
// import { issues } from '../../constants/issues'

// Actions
// import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

class Offers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 1,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
  }

  // componentDidMount(){
  //     if(this.props.match.params.id){
  //         this.props.dispatch(gameActions.fetchGames(this.props.match.params.id))
  //     }
  // }

  handleChange(e) {
    const n = e.target.name;
    const v = e.target.value;
    let data = {};
    data[n] = v;
    this.setState(data);
  }

  getIssuesDiff(of1, of2) {
    // console.log(of1.data, of2.data);
    const data1 = of1.data.map((issue) => {
      return { ...issue, ...{ values: issue.values.map((v) => v) } };
    });
    const data2 = of2.data.map((issue) => {
      return { ...issue, ...{ values: issue.values.map((v) => v) } };
    });

    const issues = data1.filter((d, di) => {
      const selected1 = d.values.find((v) => v.selected);
      const selected2 = data2[di].values.find((v) => v.selected);
      if (selected1 && selected2) return selected1.label !== selected2.label;
    });
    return issues;
  }

  setDefault(o) {
    let offer = JSON.parse(JSON.stringify(o));
    offer.data = offer.data.map((issue) => {
      // return {...issue, ...{ values: issue.values.map( v => v ) } }
      return { ...issue, ...{ values: [...issue.values.map((v) => v)] } };
    });
    offer.order = 0;
    offer.data = offer.data.map((issue, ii) => {
      issue.values = issue.values.map((v, vi) => {
        if (vi === 0) v.selected = true;
        else v.selected = false;
        return v;
      });
      return issue;
    });
    return offer;
  }

  render() {
    const { game } = this.props;

    let maxOffers = 0;
    let roundOffersPerGroup = [];
    if (game.data.groups && this.state.order > -1) {
      // Tag IssueByIssue
      roundOffersPerGroup = game.data.groups.map((p) => {
        maxOffers = p.items.length > maxOffers ? p.items.length : maxOffers;
        let realOrder = 0;
        p.items = p.items.map((it, i) => {
          const prev = i > 0 ? p.items[i - 1] : this.setDefault(p.items[0]);
          // console.log(prev, it)
          let diff = null;

          // prev = p.items[i-1];
          // IssueByIssue vs Multi-Issue
          diff = this.getIssuesDiff(prev, it);
          if (diff.length === 1) it.type = 'ibi';
          else it.type = 'pack';

          // Is this a MESOs offer?
          if (prev.order === it.order) {
            prev.type = 'meso';
            it.type = 'meso';
          }

          //   // Real counter
          //   if (prev.order !== it.order) {
          //     realOrder++;
          //   }
          realOrder++;
          it.realOrder = realOrder;

          return it;
        });
        return p;
      });
    }

    console.log({ roundOffersPerGroup });

    let offersIssueByIssue = 0;
    let offersPackage = 0;
    let offersMesos = 0;
    let offersFinished = 0;
    roundOffersPerGroup.map((groffers, goi) => {
      const goffersRound = groffers.items.filter(
        (of) => of.realOrder === parseInt(this.state.order)
      );
      const goLastOffer = groffers.items[groffers.items.length - 1];
      // console.log(goffersRound)

      if (
        goLastOffer &&
        goLastOffer.realOrder <= this.state.order &&
        goLastOffer.status === 'accepted'
      ) {
        offersFinished++;
      } else if (goffersRound.length === 1) {
        if (goffersRound[0].type === 'ibi') offersIssueByIssue++;
        if (goffersRound[0].type === 'pack') offersPackage++;
        if (goffersRound[0].type === 'meso') offersMesos++;
      } else if (goffersRound.length > 1) {
        offersMesos++;
      }
    });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Offers
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='row'>
          <div className='col-6'>
            <h5 className='mt-5'>Issue-By-Issue:</h5>
            <p className='lead'>
              In these negotiations, the parties agree upon one issue at a time.
              This strategy typically takes longer, often becomes a sequential
              distributive negotiation, and may arrive at an impasse as
              contentiousness is often greater between the two parties.
            </p>

            <h5 className='mt-5'>Package Offer:</h5>
            <p className='lead'>
              In this scenario, a student makes an offer that presents positions
              on multiple issues simultaneously. For example, a recruiter might
              give a first package offer of 15 vacation days, a position in
              Chicago, and a $75,000 base salary. This makes it easier to find
              common ground since you are trading off on entire issues instead
              of the distribution within each issue. This increased flexibility
              (real and perceived) also improves efficiency and outcomes by
              enabling you to rank priorities and provide information on
              interests, priorities, & potential trade-offs.
            </p>

            <h5 className='mt-5'>
              Multiple Equivalent Simultaneous Offers (MESOs):
            </h5>
            <p className='lead'>
              Offers that provide a choice of two or more package offers to the
              recipient. The offers are equivalent – i.e., although each offer
              may differ in its issue positions, the offeror considers them the
              same value.
            </p>
          </div>
          <div className='col-6'>
            <h5 className='text-center'>Count of Offer Tactic Over Time</h5>
            <p className='lead text-center mb-0'>Offer: {this.state.order}</p>
            <input
              name='order'
              type='range'
              min='1'
              max={maxOffers}
              value={this.state.order}
              onChange={this.handleChange}
            />

            <div className='mt-3'>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  title: { text: '' },
                  chart: { type: 'column' },
                  credits: { enabled: false },
                  legend: { enabled: false },
                  xAxis: {
                    categories: [
                      'Issue by Issue',
                      'Package Offer',
                      'MESOs',
                      'Finished Negotiation',
                    ],
                  },
                  yAxis: {
                    title: { text: 'Count' },
                    allowDecimals: false,
                    min: 0,
                    max: game.data.groups ? game.data.groups.length + 1 : 10,
                  },
                  series: [
                    {
                      data: [
                        offersIssueByIssue,
                        offersPackage,
                        offersMesos,
                        offersFinished,
                      ],
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedPage = connect(mapStateToProps)(Offers);
export { connectedPage as GameOffers };
