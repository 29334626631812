import React from 'react';
// import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import numeral from 'numeral';

// Actions
import { gameActions } from '../../actions';

import { Modal, ModalBody, Tooltip } from 'reactstrap';
import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import List from './list';
import GroupDebrief from '../../components/GroupDebrief';

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      group: null,
      tooltips: {},
    };

    // Bindings
    this.rowClick = this.rowClick.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  totalPoints(o) {
    let total = [0, 0];
    if (o.data) {
      for (let i = 0; i < o.data.length; i++) {
        const issue = o.data[i];
        const selected = issue.values.find((v) => v.selected);
        if (selected) {
          total[0] += selected.points[0];
          total[1] += selected.points[1];
        }
        if (i === o.data.length - 1) return total;
      }
    }
  }

  getMesos(offers) {
    return offers.filter((it, i) => {
      const prev = i > 0 ? offers[i - 1] : null;
      return prev && prev.order === it.order;
    });
  }

  extractValues(offer) {
    if (offer)
      return offer.data.map((issue) => issue.values.find((v) => v.selected));
    else return [];
  }

  rowClick(data, index) {
    this.setState({ group: data });
  }

  toggleTooltip(idx) {
    let tips = { ...this.state.tooltips };
    tips[idx] = tips[idx] !== undefined ? !tips[idx] : true;
    console.log(tips);
    this.setState({ tooltips: tips });
  }

  render() {
    const { game } = this.props;
    let groups = game.data.groups || [];
    groups = JSON.parse(JSON.stringify(groups));
    groups = groups.map((p, pi) => {
      const accepted = p.items.find((i) => i.status === 'accepted');
      const mesos = this.getMesos(p.items);
      const outcome = accepted ? this.totalPoints(accepted) : [0, 0];
      const joclass = accepted
        ? outcome[0] + outcome[1] === 13200
          ? 'text-success font-weight-bold'
          : ''
        : 'text-warning';
      const values = this.extractValues(accepted);
      const totalSatisfaction = p.players.reduce(
        (acc, pl) => acc + pl.satisfaction,
        0
      );

      p.group = {
        value: pi + 1,
        label: (
          <button
            className='btn btn-outline-primary'
            style={{ lineHeight: '1' }}>
            {pi + 1}
          </button>
        ),
      };
      p.email = {
        value: p.players[0].email,
        label: p.players.map((pl) => (
          <span key={`pl-${pl._id}`} className='d-block'>
            {`${pl.name} ${pl.lastname}`.trim()
              ? `${pl.name} ${pl.lastname}`
              : pl.email}{' '}
            <small>({pl.satisfaction || ' '})* </small>
          </span>
        )),
      };
      p.satisfaction = totalSatisfaction;
      p.mesos = accepted ? (mesos.length > 0 ? 'Yes' : 'No') : 'No Deal';
      p.pts_candidate = accepted
        ? { value: outcome[0], label: numeral(outcome[0]).format('0,0') }
        : 'No Deal';
      p.pts_recruiter = accepted
        ? { value: outcome[1], label: numeral(outcome[1]).format('0,0') }
        : 'No Deal';
      p.pts_total = accepted ? (
        {
          value: outcome[0] + outcome[1],
          label: (
            <span className={joclass}>
              {numeral(outcome[0] + outcome[1]).format('0,0')}
            </span>
          ),
        }
      ) : (
        <b className='text-warning'>No Deal</b>
      );
      p.salary = accepted ? values[0].label : 'No Deal';
      p.location = accepted ? (
        values[1].label !== 'San Francisco' ? (
          <b className='text-danger'>{values[1].label}</b>
        ) : (
          values[1].label
        )
      ) : (
        'No Deal'
      );
      p.bonus = accepted
        ? { label: values[2].label, value: parseInt(values[2].label) }
        : 'No Deal';
      p.vacation = accepted ? values[3].label : 'No Deal';
      p.movexp = accepted
        ? { label: values[4].label, value: parseInt(values[4].label) }
        : 'No Deal';
      p.insurance = accepted ? values[5].label : 'No Deal';
      p.job = accepted ? (
        values[6].label !== 'Division A' ? (
          <b className='text-danger'>{values[6].label}</b>
        ) : (
          values[6].label
        )
      ) : (
        'No Deal'
      );
      p.starting = accepted ? values[7].label : 'No Deal';

      return p;
    });

    groups = groups.filter((g) => g);
    // console.log(this.props)

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Leaderboard
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div style={{ fontSize: '14px' }}>
          <List
            labels={[
              'Pair',
              <>
                Learners <small>(Satisfaction)*</small>
              </>,
              'Satisfaction',
              'MESOs',
              'JointOutcome',
              'Candidate',
              'Recruiter',
              'Salary',
              'Location',
              'Bonus',
              'Vacation',
              'MovExp',
              'Insurance',
              'Job',
              'Start',
            ]}
            fields={[
              'group',
              'email',
              'satisfaction',
              'mesos',
              'pts_total',
              'pts_candidate',
              'pts_recruiter',
              'salary',
              'location',
              'bonus',
              'vacation',
              'movexp',
              'insurance',
              'job',
              'starting',
            ]}
            sortables={[
              'group',
              'email',
              'satisfaction',
              'mesos',
              'pts_total',
              'pts_candidate',
              'pts_recruiter',
              'salary',
              'location',
              'bonus',
              'vacation',
              'movexp',
              'insurance',
              'job',
              'starting',
            ]}
            data={groups}
            onRowClick={this.rowClick}
          />
        </div>

        <Modal
          isOpen={this.state.group ? true : false}
          centered={true}
          size='xxl'
          toggle={() => this.setState({ group: null })}>
          <ModalBody className='text-center'>
            {this.state.group &&
              this.state.group.players.length === game.data.ppg && (
                <GroupDebrief group={this.state.group} />
              )}
            {this.state.group &&
              this.state.group.players.length !== game.data.ppg && (
                <p className='mb-0'>Incomplete pair. No debrief available.</p>
              )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedLeaderboardPage = connect(mapStateToProps)(Leaderboard);
export { connectedLeaderboardPage as GameLeaderboard };
