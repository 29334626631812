import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setupSocket, enterBackend, socketOn } from '../../helpers';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Header from '../../components/Header';
import CSVDrop from '../../components/CSVDrop';
import csv_template from '../../assets/simcase_csv_template.csv';

// Actions
import { gameActions, modalActions } from '../../actions';

// Components
const GroupSlots = (props) => {
  const {
    index,
    // group_id,
    ppg,
    roles,
    players,
    disabled,
    onRemove,
    // onGroupRemove
  } = props;
  const slots = [...Array(ppg).keys()];
  const slotDivs = slots.map((s) => {
    const player = players && players[s] && players[s]._id ? players[s] : null;
    const rindex = player ? player.rindex : s;
    const role = roles && roles[rindex] ? roles[rindex] : '';
    const playerName = player ? (
      `${player.name} ${player.lastname}`
    ) : (
      <i className='text-danger'>INCOMPLETE GROUP</i>
    );
    return (
      <li
        key={`g${index}p${s}`}
        className={`list-group-item ${!player ? 'bg-light' : ''}`}
        style={{ borderStyle: !player ? 'dashed' : 'solid' }}>
        {/* <span className="d-inline-block text-muted mr-3">Group { index+1 }</span> */}
        <small
          className='d-inline-block text-muted mr-3'
          style={{ minWidth: '80px' }}>
          {role}:
        </small>
        {playerName}
        {player && <small className='ml-3 text-muted'>{player.email}</small>}
        {player && (
          <button
            disabled={disabled}
            className={`float-right btn btn-sm ${
              disabled ? 'btn-outline-secondary' : 'btn-outline-danger'
            }`}
            style={{ fontSize: '10px', padding: '.2rem .4rem' }}
            onClick={(e) => onRemove && onRemove([players[s]._id])}>
            Remove
          </button>
        )}
      </li>
    );
  });
  return <div className='mb-3'>{slotDivs}</div>;
};

const setGroups = (players, ppg) => {
  //Validations
  if (!players || !players.length) return [];
  if (ppg < 2) {
    console.log('Groups need more than 1 ppg. Received ' + ppg + '.');
    return [];
  }

  let i,
    j,
    tempArray,
    groupsArray = [],
    chunk = ppg;
  for (i = 0, j = players.length; i < j; i += chunk) {
    tempArray = players.slice(i, i + chunk);
    groupsArray.push({ players: tempArray });
    if (i + chunk >= j) {
      return groupsArray;
    }
  }
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onboarding: true,
      item: this.props.game.data || {},
      changed: false,
      fetching: false,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.launch = this.launch.bind(this);
    this.readCSV = this.readCSV.bind(this);
    this.removePlayers = this.removePlayers.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { item } = this.state;

    fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setupSocket(data.io_domain, () => {
          if (item && item._id) {
            enterBackend({ game_id: item._id }, (gameData) => {
              console.log(`Connected to ${gameData._id} ws backend.`);
            });
            // Sockets!
            socketOn('player', () => {
              dispatch(gameActions.fetchGames(item._id));
            });
          }
        });
      });
  }

  componentWillReceiveProps(newProps) {
    // Everytime REDUX updates the store
    // This hook will get executed and the state will get updated
    this.setState({ item: newProps.game.data });
  }

  handleChange(e) {
    const { name, value } = e.target;
    let item = { ...this.state.item };
    item[name] = name === 'players_text' ? value.replace(/\t/g, ', ') : value;
    item['changed'] = true;
    this.setState({ item }, () => {
      if (name === 'access_type') this.submit();
    });
  }

  submit() {
    this.props.dispatch(gameActions.updateGame(this.state.item));
  }

  launch() {
    this.props.dispatch(gameActions.launchGame(this.state.item));
  }

  readCSV(content) {
    let item = { ...this.state.item };
    item.players_text = content;
    this.props.dispatch(gameActions.updateGame(item));
  }

  removePlayers(ids) {
    const { dispatch } = this.props;
    const { item } = this.state;
    if (item && item._id) {
      const modalBody = (
        <p className='mt-3 text-center'>
          You are about to REMOVE a learner from the session.
        </p>
      );
      const modalButtons = [
        <button
          key='modal-btn-close'
          className='btn btn-light'
          onClick={() => dispatch(modalActions.close())}>
          Cancel
        </button>,
        <button
          key='modal-btn-delete'
          className='btn btn-danger'
          onClick={() => {
            dispatch(gameActions.removePlayers(item._id, ids));
            dispatch(modalActions.close());
          }}>
          Remove
        </button>,
      ];
      // Open the modal
      dispatch(
        modalActions.open({
          title: 'Please Confirm',
          body: modalBody,
          buttons: modalButtons,
        })
      );
    }
  }

  render() {
    const { item, onboarding } = this.state;

    const okPPG = item.registered.length % item.ppg === 0 ? true : false;

    if (item.status === 'launched')
      return <Redirect to={'/games/' + item._id + '/players'} />;

    const tempGroups = setGroups(item.players, item.ppg);

    return (
      <div className=''>
        <Header />

        <h2 className='mt-3 sec-title' style={{ lineHeight: 1.3 }}>
          Simulation Setup{' '}
          <small
            style={{
              fontSize: '12px',
              padding: '5px 8px',
              verticalAlign: 'middle',
            }}
            className={
              item.status === 'saving'
                ? 'text-capitalize badge badge-pill badge-warning'
                : 'text-capitalize badge badge-pill badge-success'
            }>
            {item.status === 'saving' ? 'saving...' : item.status}
          </small>
          <div className='float-right' style={{ lineHeight: 1 }}>
            <button
              className='btn btn-md btn-link text-dark mr-5'
              onClick={() => this.setState({ onboarding: true })}>
              <small className='font-italic'>Need help?</small>
            </button>
            <button
              className='btn btn-md btn-outline-primary'
              onClick={this.submit}
              disabled={item.status === 'saving' ? true : false}>
              Save
            </button>
            <button
              className='btn btn-md btn-outline-success ml-3'
              onClick={this.launch}
              disabled={
                item.status === 'saving' || item.changed || !okPPG
                  ? true
                  : false
              }>
              Launch
            </button>
          </div>
        </h2>

        <div className='row mt-4'>
          <div className='col-6'>
            <div className='row'>
              <div className='col-8'>
                <div className='form-group'>
                  <label>Game Name</label>
                  <div className='input-group input-group-lg'>
                    <input
                      name='name'
                      className='form-control'
                      placeholder='Enter game name'
                      disabled={item.status === 'saving' ? true : false}
                      value={item.name || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='col-4'>
                <div className='form-group'>
                  <label>Access Type</label>
                  <div className='input-group input-group-lg'>
                    <select
                      name='access_type'
                      className='form-control'
                      value={item.access_type || ''}
                      onChange={this.handleChange}
                      disabled={item.status === 'saving' ? true : false}>
                      <option value='csv'>Upload Emails</option>
                      <option value='link'>Link</option>
                    </select>
                  </div>
                </div>
              </div>
              {item.access_type === 'link' && (
                <div className='col-12 mt-4'>
                  <div className='card'>
                    <div className='card-body bg-dark text-center pt-5 pb-5 pl-5 pr-5'>
                      <code
                        className='text-white'
                        style={{ fontSize: '1.5rem' }}>
                        {item.short_url
                          ? item.short_url
                          : process.env.REACT_APP_GAME_URL +
                            '/join/' +
                            item._id}
                      </code>
                    </div>
                  </div>
                </div>
              )}
              {item.access_type === 'csv' && (
                <div className='col-12 mt-4'>
                  <div className='card'>
                    <div className='card-body text-left pt-3 pb-3 pl-3 pr-3'>
                      <h5 className='mb-3'>Instructions</h5>
                      <ol>
                        <li className='mb-3'>
                          Structure your class list in a spreadsheet as follows
                          (click{' '}
                          <b>
                            <a href={csv_template} target='_blank'>
                              here
                            </a>
                          </b>{' '}
                          to download a copy):
                          <table className='table table-sm table-bordered table-striped mt-3'>
                            <tbody>
                              <tr>
                                <td>player1@email.com</td>
                                <td>Name</td>
                                <td>Surname</td>
                              </tr>
                              <tr>
                                <td>player2@email.com</td>
                                <td>Name</td>
                                <td>Surname</td>
                              </tr>
                              <tr>
                                <td>player3@email.com</td>
                                <td>Name</td>
                                <td>Surname</td>
                              </tr>
                              <tr>
                                <td>player4@email.com</td>
                                <td>Name</td>
                                <td>Surname</td>
                              </tr>
                            </tbody>
                          </table>
                          <p>
                            <b>Note</b>: The roles in this game are assigned in
                            the following order:
                          </p>
                          The first email in the list → Candidate
                          <br />
                          The second email in the list → Recruiter
                          <br />
                          The third email in the list → Candidate <br />
                          The fourth email in the list → Recruiter
                        </li>
                        <li className='mb-3'>
                          Copy and paste your data into the 'Registered Players'
                          text area at right.
                        </li>
                        <li className='mb-0'>
                          Click 'Save', verify the emails and names, then click
                          'Launch'.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='form-group'>
              <label>
                Registered Players{' '}
                <span className='badge badge-warning badge-pill'>
                  {item.registered ? item.registered.length : 0}
                </span>
                {item.access_type === 'link' && (
                  <small
                    className='btn btn-link btn-sm ml-2 font-italic'
                    onClick={(e) => {
                      this.setState({ fetching: true });
                      this.props.dispatch(gameActions.fetchGames(item._id));
                      setTimeout(
                        () => this.setState({ fetching: false }),
                        1000
                      );
                    }}>
                    {this.state.fetching ? 'Reloading...' : 'Reload list'}
                  </small>
                )}
                {/* <button
                  className='btn btn-sm btn-outline-primary ml-3'
                  onClick={(e) => {
                    this.props.dispatch(gameActions.fetchGames(item._id));
                  }}>
                  <FontAwesomeIcon icon='redo-alt' />
                </button> */}
              </label>

              {item.registered.length % item.ppg !== 0 && (
                <small
                  style={{
                    fontSize: '12px',
                    padding: '5px 8px',
                    verticalAlign: 'middle',
                  }}
                  className='badge badge-pill badge-danger float-right'>
                  This game needs {item.ppg} players per group. Add more
                  players.
                </small>
              )}

              {item.access_type === 'csv' && (
                <CSVDrop onComplete={this.readCSV}>
                  <div className='input-group input-group-lg'>
                    <textarea
                      name='players_text'
                      className='form-control'
                      placeholder='email@example.com'
                      value={item.players_text || ''}
                      onChange={this.handleChange}
                      rows='15'
                      disabled={
                        item.status === 'saving' ? true : false
                      }></textarea>
                  </div>
                  <small>
                    <b>Tip</b>: You can also drag and drop your csv file here.
                  </small>
                </CSVDrop>
              )}

              {item.access_type === 'link' && (
                <div>
                  {tempGroups.reverse().map((g, gi) => (
                    <GroupSlots
                      key={`slots${gi}`}
                      index={gi}
                      group_id={g._id}
                      ppg={item.ppg}
                      roles={['Candidate', 'Recruiter']}
                      players={g.players}
                      disabled={item.status === 'saving' ? true : false}
                      onRemove={this.removePlayers}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal
          isOpen={onboarding}
          toggle={() => this.setState({ onboarding: !onboarding })}
          centered={true}
          size='lg'>
          <ModalHeader>Onboarding</ModalHeader>
          <ModalBody className='text-center'>
            <iframe
              src='https://player.vimeo.com/video/383603029'
              width='640'
              height='360'
              frameBorder='0'
              allow='autoplay; fullscreen'
              allowFullScreen></iframe>
          </ModalBody>
          <ModalFooter>
            <button
              className='btn btn-outline-primary'
              onClick={() => this.setState({ onboarding: !onboarding })}>
              Continue
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { game } = state;
  return {
    game,
  };
}

const connectedGamesPage = connect(mapStateToProps)(Form);
export { connectedGamesPage as GameForm };
