import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';

// Actions
// import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import GroupBtns from '../../components/GroupBtns';

// Setting Highcharts
Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
more(Highcharts);

class Types extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidate: 0,
      recruiter: 1,
      clicked: null,
      chart: 'integrative',
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.setChart = this.setChart.bind(this);
  }

  // componentDidMount(){
  //     if(this.props.match.params.id){
  //         this.props.dispatch(gameActions.fetchGames(this.props.match.params.id))
  //     }
  // }

  handleChange(e) {
    const n = e.target.name;
    const v = e.target.value;
    let data = {};
    data[n] = v;
    this.setState(data);
  }

  groupsModal(e) {
    const np = { ...e.point };
    this.setState({
      clicked: [np.x, np.y],
    });
  }

  setChart(chart) {
    this.setState({ chart });
  }

  totalPoints(data) {
    let total = [0, 0];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        const issue = data[i];
        const selected = issue.values.find((v) => v.selected && !v.default);
        if (selected) {
          total[0] += selected.points[0];
          total[1] += selected.points[1];
        }
        if (i === data.length - 1) return total;
      }
    }
  }

  groupsAcceptedOffers(groups) {
    let result = [];
    // Select the right offer per group
    let acceptedOffers = groups.map((g) => {
      let accepted = g.items.find((i) => i.status === 'accepted');
      if (accepted) accepted.gid = g._id;
      return accepted;
    });
    // Filter only valid offers
    acceptedOffers = acceptedOffers.filter((o) => o);

    if (acceptedOffers.length === 0) return result;

    // Validate offers by selected values
    for (let i = 0; i < acceptedOffers.length; i++) {
      let o = acceptedOffers[i];
      result.push(o);

      // console.log( i, acceptedOffers.length-1 )
      if (i === acceptedOffers.length - 1) return result;
    }
  }

  render() {
    const { game } = this.props;

    const titles = {
      integrative: 'Integrative Issues',
      compatible: 'Compatible Issues',
      distributive: 'Distributive Issues',
    };

    const issues = {
      integrative: [
        <span key='iss-1' className='d-inline-block mr-5'>
          Bonus
        </span>,
        <span key='iss-2' className='d-inline-block mr-5'>
          Vacation
        </span>,
        <span key='iss-3' className='d-inline-block mr-5'>
          Moving Expenses
        </span>,
        <span key='iss-4'>Insurance Coverage</span>,
      ],
      compatible: [
        <span key='iss-1' className='d-inline-block mr-5'>
          Location
        </span>,
        <span key='iss-2'>Job Assignment</span>,
      ],
      distributive: [
        <span key='iss-1' className='d-inline-block mr-5'>
          Salary
        </span>,
        <span key='iss-2'>Starting Date</span>,
      ],
    };

    const descriptions = {
      integrative:
        'Issues in which parties differ in how much they value they place on the issue. Negotiators negotiate two or more integrative issues in tandem to allow for trade-offs or to ‘expand the pie’.',
      compatible:
        'Issues in which parties have similar or identical interests.',
      distributive:
        'Issue in which the interests of the parties are in direct conflict. Distributive issues are also called ‘win-lose’ or ‘zero-sum’ issues because any value claimed by one party comes at the expense of the other party. In general, both parties try to claim as much of the ‘pie’ as possible with these issues.',
    };

    let selectedOffers = [];
    let plotPoints = [];
    let chartIssues = [];
    let groups = null;
    if (game.data.groups) {
      groups = JSON.parse(JSON.stringify(game.data.groups));

      // An array of all the accepted offers (1) per group
      selectedOffers = this.groupsAcceptedOffers(groups);

      // Loop each group accepted offer and return only the desired issues
      chartIssues = selectedOffers.map((offer) => {
        return offer.data.filter((d) => {
          // Return only the desired issues
          if (this.state.chart === 'integrative')
            return (
              ['bonus', 'vacation', 'moving', 'insurance'].indexOf(d.key) > -1
            );

          if (this.state.chart === 'compatible')
            return ['location', 'job'].indexOf(d.key) > -1;

          if (this.state.chart === 'distributive')
            return ['salary', 'starting'].indexOf(d.key) > -1;
        });
      });

      // Calculate total points for each pair accepted offer
      // Each offer contains only the desired issues
      plotPoints = selectedOffers
        .map((o, oi) => {
          const t = this.totalPoints(chartIssues[oi]);
          return { x: t[0], y: t[1], z: 10, name: oi + 1 };
        })
        .sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0));

      // console.log({ chartIssues })
      // console.log({ plotPoints })
    }

    // const plotPoints = selectedOffers.map( o => this.totalPoints(o) );

    // Prepare the groups to be listed in the pair details modal
    let modalGroups = [];
    if (this.state.clicked)
      modalGroups = selectedOffers
        .filter((offer, oi) => {
          const groupPoints = this.totalPoints(chartIssues[oi]);
          if (
            groupPoints[0] === this.state.clicked[0] &&
            groupPoints[1] === this.state.clicked[1]
          )
            return true;
          else return false;
        })
        .map((o) => {
          return groups.find((g) => g._id === o.gid);
        });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Types
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='row mt-4'>
          <div className='col-6'>
            <div className='d-flex justify-content-around'>
              <button
                onClick={() => this.setChart('integrative')}
                className={
                  'btn btn-block mr-3 ' +
                  (this.state.chart === 'integrative'
                    ? 'btn-primary'
                    : 'btn-outline-secondary')
                }>
                Integrative
              </button>
              <button
                onClick={() => this.setChart('compatible')}
                className={
                  'btn btn-block mt-0 ' +
                  (this.state.chart === 'compatible'
                    ? 'btn-primary'
                    : 'btn-outline-secondary')
                }>
                Compatible
              </button>
              <button
                onClick={() => this.setChart('distributive')}
                className={
                  'btn btn-block mt-0 ml-3 ' +
                  (this.state.chart === 'distributive'
                    ? 'btn-primary'
                    : 'btn-outline-secondary')
                }>
                Distributive
              </button>
            </div>

            <h5 className='mt-5'>Issues:</h5>
            <p className='lead'>{issues[this.state.chart]}</p>

            <h5 className='mt-5'>Description:</h5>
            <p className='lead'>{descriptions[this.state.chart]}</p>
          </div>
          <div className='col-6'>
            <h5 className='text-center'>
              {titles[this.state.chart]}: Candidate vs Recruiter (pts)
            </h5>

            <div className='mt-3'>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  title: { text: '' },
                  chart: { type: 'scatter' },
                  credits: { enabled: false },
                  legend: { enabled: false },
                  xAxis: {
                    title: { text: 'Candidate (pts)' },
                    minRange: 10000,
                    tickInterval: 1000,
                    allowDecimals: false,
                    plotBands: [
                      { color: 'rgba(0,0,0,.1)', width: 2, value: 0 },
                    ],
                  },
                  yAxis: {
                    title: { text: 'Recruiter (pts)' },
                    minRange: 10000,
                    allowDecimals: false,
                    tickInterval: 1000,
                    plotLines: [
                      { color: 'rgba(0,0,0,.1)', width: 2, value: 0 },
                    ],
                  },
                  plotOptions: {
                    scatter: {
                      marker: {
                        radius: 15,
                        fillColor: 'rgba(0,0,0,.3)',
                        states: {
                          hover: {
                            enabled: true,
                            lineColor: 'rgba(0,0,0,.4)',
                          },
                        },
                      },
                      states: {
                        hover: {
                          marker: {
                            enabled: false,
                          },
                        },
                      },
                      tooltip: {
                        // headerFormat: '<b>{series.name}</b><br>',
                        headerFormat: '',
                        pointFormat:
                          '<b>Candidate</b>: {point.x:,.0f} pts<br/><b>Recruiter</b>: {point.y:,.0f} pts',
                      },
                    },
                    series: {
                      cursor: 'pointer',
                      point: {
                        events: {
                          click: (e) => this.groupsModal(e),
                        },
                      },
                    },
                  },
                  series: [
                    {
                      data: plotPoints,
                      // data: [
                      //     {x: 2800, y: 3800, z: 10, name: 7},
                      //     {x: 4000, y: 8000, z: 10, name: 4},
                      //     {x: 5000, y: 6400, z: 10, name: 5},
                      //     {x: 5200, y: 8000, z: 10, name: 1},
                      //     {x: 5800, y: 6800, z: 10, name: 2},
                      //     {x: 7200, y: 3600, z: 10, name: 3},
                      //     {x: 7200, y: 7200, z: 10, name: 6}
                      // ]
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>

        {this.state.clicked && (
          <GroupBtns
            onClose={() => this.setState({ clicked: null })}
            modalGroups={modalGroups}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedPage = connect(mapStateToProps)(Types);
export { connectedPage as GameTypes };
