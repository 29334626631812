import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import numeral from 'numeral';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { issues } from '../constants/issues'

class Builder extends React.Component {
  
    constructor(props) {
        super(props);

        this.state = {
            _id: typeof this.props.id != 'undefined' ? this.props.id : null,
            editable: typeof this.props.editable != 'undefined' ? this.props.editable : true,
            acceptable: typeof this.props.acceptable != 'undefined' ? this.props.acceptable : false,
            active: 0,
            author: this.props.author,
            status: this.props.status || '',
            issues: (this.props.issues && this.props.issues.length > 0) ? this.props.issues : issues,
            accept_modal: false
        }

        // Bindings
        this.activateIssue = this.activateIssue.bind(this);
        this.sliderChange  = this.sliderChange.bind(this);
        this.onSubmit      = this.onSubmit.bind(this);
        this.onAccept      = this.onAccept.bind(this);
        this.onCancel      = this.onCancel.bind(this);
        this.toggleModal   = this.toggleModal.bind(this);
        this.likeIt        = this.likeIt.bind(this);
        this.clearBuilder  = this.clearBuilder.bind(this);

    }

    componentWillReceiveProps( newProps ){
        let newState = {};
                
        if( newProps.editable )
            newState['editable'] = newProps.editable;

        if( newProps.acceptable )
            newState['acceptable'] = newProps.acceptable;

        if( newProps.status )
            newState['status'] = newProps.status;
            
        if( newProps.issues && newProps.issues.length > 0 )
            newState['issues'] = newProps.issues;

        this.setState(newState)
    }

    activateIssue( issueIndex ){
        this.setState({ active: issueIndex });
    }

    // The name of the input will be used to update the state with the input value
    sliderChange(event) {
        let issues = [...this.state.issues];
        let currentIssue = issues[this.state.active];
        // const name = parseInt(event.target.name);
        const value = parseInt(event.target.value);
        // Unselect them all
        currentIssue.values = currentIssue.values.map( v => {
            v.selected = false;
            return v;
        });

        // Select the right one
        currentIssue.values[value].selected = true;

        // Update the state & propagate to parent
        this.setState({ issues }, () => {
            if(this.props.onChange)
                this.props.onChange(issues);
        });
    }

    onSubmit(){
        const issues = [...this.state.issues];
        if( this.props.onSubmit )
            this.props.onSubmit(issues);
    }

    onAccept(){
        this.toggleModal(false)
        if( this.props.onAccept )
            this.props.onAccept();
    }

    onCancel(){
        if( this.props.onCancel )
            this.props.onCancel();
    }

    toggleModal( mode ){
        this.setState({ accept_modal: mode });
    }

    likeIt(){
        let issues = [...this.state.issues];
        let currentIssue = issues[this.state.active];
        // Set selected value to like
        let currentValue = currentIssue.values.find( v => v.selected );
        if( currentValue )
            currentValue.like = !currentValue.like;
        // Update the state
        this.setState({ issues }, () => {
            if(this.props.onLikeToggle)
                this.props.onLikeToggle(this.state);
        })
    }

    clearBuilder(){
        const newissues = issues.map( issue => {
            let newiss = {...issue}
            newiss.values = newiss.values.map( v => ({...v}) )
            return newiss
        })
        this.setState({ issues: newissues })
    }

    render() {

        const { player } = this.props;
        const mine = player._id === this.state.author ? true : false;
        // const roleIndex = typeof this.props.authorRole !== 'undefined' ? this.props.authorRole : 0;

        const issues = this.state.issues.map( (i,ii) => {
            let issueValue = i.values.find( v => v.selected === true );
            let activeClass = i.values.find( v => v.selected === true && !v.default ) ? 'btn-outline-kellogg' : 'btn-secondary';
            let likedClass = i.values.find( v => v.like === true ) ? 'text-danger' : 'text-grey';
            activeClass = ii === this.state.active ? 'btn-kellogg' : activeClass;
            const cols = this.props.cols ? this.props.cols : 'col-6 col-sm-3'
            return <div className={`${cols} mb-1`} key={'issue-'+ii}>
                <span className={'btn btn-sm btn-block issue mb-2 ml-1 mr-1 ' + activeClass} onClick={ () => this.activateIssue(ii) }>
                {/* {i.button} <br />
                { issueValue && !issueValue.default ? issueValue.points[player.rindex] : '--' } */}
                {i.button} 
                <div className="clearfix mt-1 mb-1">
                    <small className="float-left">{ issueValue && issueValue.label }</small>
                    <small className="float-right">{ issueValue && !issueValue.default ? issueValue.points[player.rindex] : '--' }</small>
                </div>
                {/* { likedClass === 'text-danger' && <FontAwesomeIcon icon={faHeart} className={'icon issue-like d-block ' + likedClass} style={{
                    fontSize: '1rem',
                    lineHeight: '0',
                    margin: '0 auto -1rem auto'
                }} /> } */}
                </span>
            </div>;
        });

        const activeIssue = this.state.issues[this.state.active] || null;
        activeIssue.values.sort(function(a, b){ return a.points[player.rindex] - b.points[player.rindex] });
        const activeIssueIndex = activeIssue.values.findIndex( v => v.selected === true );
        const activeIssueValue = activeIssue.values.find( v => v.selected === true );
        const activeIssueLike  = activeIssue.values.find( v => v.like === true ) ? 'text-danger' : 'text-grey';

        // const activeIssueOffer = this.state.offer[this.state.active] || null;
        const valuedIssues = this.state.issues
            .map( i => i.values.find( v => v.selected ) )
            .filter( i => i );
        // console.log({ valuedIssues })
        const totalPoints = this.state.issues
            .map( i => i.values.find( v => v.selected && !v.default ) )
            .filter( i => i )
            .map( i => i.points[player.rindex] )
            .reduce( (prev, curr) => prev + curr, 0 );

        let roleBox = mine ? 'bg-kellogg' : 'bg-white';

        return (
            <div className={'card offer-box ' + roleBox}>
                <div style={{ padding: '10px 15px' }}>

                    <div className="clearfix text-kellogg">
                        <small className="float-left">
                            Offer #{ this.props.index } 
                            { this.state.editable && <b 
                                style={{ fontSize: '10px', padding: '0 3px' }}
                                className="d-inline-block ml-2 btn btn-warning" 
                                onClick={ this.clearBuilder }>Clear</b> }
                        </small>
                        <small className="float-right">
                            Total Points: { numeral(totalPoints).format('0,0') }
                            { this.state.editable && 
                                <FontAwesomeIcon 
                                    icon={ faTimesCircle } 
                                    size="2x"
                                    className="icon bg-white text-danger"
                                    style={{
                                        position: 'absolute',
                                        top: '-12px',
                                        right: '-7px',
                                        cursor: 'pointer',
                                        borderRadius: '50%'
                                    }}
                                    onClick={ this.onCancel } />
                            }
                        </small>
                    </div>

                    <div className="issues-box text-center mt-3">

                        <div className="row">
                            { issues }
                        </div>

                        { activeIssue && <div className="mt-3">

                            <div className="clearfix mb-1">
                                <p className="lead mb-0 clearfix">
                                    <span className="float-left text-kellogg" style={{ fontSize: '1.3rem' }}>

                                        <b style={{ fontWeight: 'bold' }}>{ activeIssue.label }</b>:&nbsp;
                                        { activeIssueValue && activeIssueValue.label }
                                        { !activeIssueValue && '--' }

                                        {/* { !this.state.editable && <FontAwesomeIcon 
                                            icon={faHeart} 
                                            className={'icon issue-like ' + activeIssueLike}
                                            style={{ marginLeft: '5px' }}
                                            onClick={ this.likeIt }
                                            /> } */}

                                    </span>
                                    <small className="text-muted float-right" style={{ fontSize: '1rem' }}>
                                        { activeIssueValue && !activeIssueValue.default && activeIssueValue.points[player.rindex] + ' pts'}
                                    </small>
                                </p>
                            </div>

                            { this.state.editable && <div className="mt-0">
                                    <input 
                                        type="range" 
                                        name="" 
                                        className="" 
                                        min={0} 
                                        max={activeIssue.values.length-1} 
                                        step={1} 
                                        value={activeIssueIndex}
                                        onChange={ this.sliderChange } 
                                        />
                                    {/* <div className="clearfix mb-1">
                                        <span className="btn btn-light text-kellogg mt-3 float-left" onClick={ this.onSubmit }>Send Offer</span>
                                        <span className="btn btn-light text-danger mt-3 float-right" onClick={ this.onCancel }>Cancel Offer</span>
                                    </div> */}

                                    <div className="" style={{ position: 'relative', height: '15px' }}>
                                        { activeIssue.values.map( (v, vi) => <small key={`sp-${vi}`} 
                                            // style={{ width: 100/(activeIssue.values.length-2) + '%' }} className="text-right">
                                            style={{ 
                                                position: 'absolute',
                                                display: 'inline-block',
                                                width: 50 + 'px',
                                                left: 100/(activeIssue.values.length-1) * vi + '%',
                                                marginLeft: (-50/(activeIssue.values.length-1) * vi) + 'px'
                                            }} className="text-center">
                                            {/* { vi > 0 ? v.points[player.rindex] : '--' } */}
                                            { vi > 0 ? numeral(v.points[player.rindex]).format('0,0') : '' }
                                        </small> ) }
                                    </div>

                                </div>
                            }

                            { !mine && this.state.acceptable && !this.state.editable && this.state.status === 'init' && valuedIssues.length === 8 && <div className="mt-4">
                                <span className="btn btn-outline-kellogg mb-1" onClick={ () => this.toggleModal(true) }>Accept Offer</span>
                            </div> }

                        </div> }

                    </div>

                    <Modal isOpen={ this.state.accept_modal }>
                        <ModalBody>
                            Confirm that you want to accept this offer.
                        </ModalBody>
                        <ModalFooter>
                            <Button color="outline-kellogg" onClick={ this.onAccept }>Accept</Button>
                            <Button color="secondary" onClick={() => this.toggleModal(false)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    // const { player } = state;
    // return { player };
}

export default connect(mapStateToProps)(Builder);