import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// import ReactGA from 'react-ga';

// import queryString from 'query-string';
import { history } from './helpers';
import { authActions } from './actions';

// import { PrivateRoute } from './components/PrivateRoute';
// import { HomePage } from './containers/home';

import { HomePage } from './containers/Home';
import { AuthPage } from './containers/Auth';
import { GamesPage } from './containers/games/index';
import { GameItem } from './containers/games/item';
import { GameNew } from './containers/games/new';

// GLOBAL MODALS
import ConfirmModal from './components/ComfirmModal';

import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    // Bindings
  }

  // componentDidMount(){}

  render() {
    const { dispatch } = this.props;

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div id='app' className='col-12'>
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  path='/'
                  render={(props) => <HomePage {...props} />}
                />

                <Route
                  exact
                  path='/authenticate'
                  render={(props) => <AuthPage {...props} />}
                />

                {/* <Route exact path="/logout" render={ props => 
                                    <HomePage {...props} /> } /> */}

                <Route
                  exact
                  path='/logout'
                  component={(props) => {
                    dispatch(authActions.logout());
                    return null;
                  }}
                />

                <Route
                  exact
                  path='/games'
                  render={(props) => <GamesPage {...props} />}
                />

                <Route
                  exact
                  path='/games/new'
                  render={(props) => <GameNew {...props} />}
                />

                <Route
                  path='/games/:id'
                  render={(props) => <GameItem {...props} />}
                />
              </Switch>
            </Router>

            <ConfirmModal />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

export default connect(mapStateToProps)(App);
